import * as React from "react"
import Layout from "../layouts"
import Seo from "../components/seo"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  BreadCrumb,
  Container,
  Section,
  SectionTitle,
  SectionDescription,
  MarkdownBody,
  MarkdownContent,
  SectionInnerWhite,
} from "../components/Section"
import PrimaryButton from "../components/Button/PrimaryButton"
import PhoneIcon from "../components/Icons/PhoneIcon"
import BreakpointUp from "../components/Media/BreakpointUp"
import BreakpointDown from "../components/Media/BreakpointDown"
import {
  HeroContainer,
  Hero,
  Background,
  HeroCaption,
  HeroTitle,
  HeroDescription,
  HeroVideo,
} from "../components/Hero"
import {
  SectionExpert,
  SectionToolbar,
  ExpertBackground,
  SectionExpertContent,
} from "../components/CallExpert"
// import ReviewCard from "../components/ReviewCard"
// import DefaultButton from "../components/Button/DefaultButton"
// import LineArrowRight from "../components/Icons/LineArrowRight"

const SectionAbout = styled.div`
  background-color: #fff;
  padding: 0 4.166%;
  position: relative;
  box-shadow: 0 25px 50px rgb(3 51 102 / 20%);
  ${BreakpointUp.xxl`    
    padding:0 8.333%;
  `}
`
const AboutBackground = styled.div`
  width: 75%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 0.1;
  .gatsby-image-wrapper {
    height: 100%;
    display: block;
  }
  &:after {
    content: "";
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
  }
`
const AboutGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 -10px;
`
const AboutGridLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  display: none;
  ${BreakpointUp.lg` 
    flex: 0 0 30%;
    max-width: 30%;
    display:flex;
  `}
`
const AboutGridRight = styled.div`
  position: relative;
  width: 100%;
  padding: 30px 10px;
  ${BreakpointUp.lg`
    flex: 0 0 70%;
    max-width: 70%;
  `}
  ${BreakpointUp.xl`
    padding:40px 10px;
    flex: 0 0 70%;
    max-width: 70%;
  `}
  ${BreakpointUp.xxl`
    padding:60px 10px;
    flex: 0 0 70%;
    max-width: 70%;
  `}
`
// const SectionReview = styled.div`
//   background-color:#F5FAFF;
//   box-shadow:0 25px 50px rgba(3,51,102,0.2);
//   padding:40px 4.166%;
//   ${BreakpointUp.xl`
//     padding:60px 4.166%;
//   `}
//   ${BreakpointUp.xxl`
//     padding:90px 8.333%;
//   `}
// `
// const SectionGrid = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   margin:0 -10px;

// `
// const SectionGridCol = styled.div`
//   position: relative;
//   width:100%;
//   padding:0 10px;
//   ${BreakpointUp.lg`
//     flex-basis: 0;
//     flex-grow: 1;
//     max-width: 100%;
//   `}
//   ${BreakpointDown.sm`
//     &:last-child{
//       .review-card{
//         &:after{
//           display:none;
//         }
//       }
//     }
//   `}
//   .review-card{
//     .date{
//       display:none;
//     }
//     .card-desc{
//       display: -webkit-box;
//       -webkit-line-clamp: 8;
//       -webkit-box-orient: vertical;
//       overflow: hidden;
//       p{
//         color: #444;
//         font-weight: 500;
//         font-style: italic;
//       }
//     }
//   }
// `
// const ExploreMore = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   max-width: 340px;
//   width: 100%;
//   display: block;
//   margin:20px auto 0 auto;
//   ${BreakpointUp.sm`
//     position: absolute;
//     left:50%;
//     transform:translateX(-50%);
//     bottom: -22px;
//     margin:0;
//   `}
//   ${BreakpointUp.lg`
//     bottom: -26px;
//   `}
//   .btn{
//     width:100%;
//   }
// `

const GridRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const GridCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.lg` 
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  `}
  &:last-child {
    .card {
      &:before {
        ${BreakpointDown.lg`
          display:none;
        `}
      }
      .card-action {
        ${BreakpointDown.lg`
          display:none;
        `}
      }
    }
  }
`
const Card = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  padding: 20px 35px 20px 15px;
  height: calc(100% - 30px);
  background: #fff;
  transition: all 0.3s ease-in-out;
  ${BreakpointUp.xxl`
    padding:30px 45px 30px 30px;
  `}
  &:after {
    content: "";
    position: absolute;
    background-color: #fff;
    right: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    height: 30px;
    display: none;
    ${BreakpointUp.lg`
      display:block;
      right: 0;
      top: 0;
      bottom: 0;
      width: 30px;
      height: 100%;

    `}
    ${BreakpointUp.xxl`
      width: 40px;
    `}
  }
  &:before {
    content: "";
    position: absolute;
    top: auto;
    right: auto;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 80%;
    height: 2px;
    background: #efefef;
    ${BreakpointUp.lg`
      height:10px;
      box-shadow:0 15px 25px rgba(3,51,102,0.20);
      background:transparent;
      top:50%;
      right:0;
      left:auto;
      bottom:auto;
      transform:translateY(-50%);
      width:10px;
      height:80%;
    `}
    ${BreakpointUp.xxl`
      width:15px;
    `}
  }
`
const CardTitle = styled.h3`
  position: relative;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 26px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
  @media (min-width: 1600px) {
    font-size: 24px;
    line-height: 30px;
  }
`
const CardBody = styled.div`
  position: relative;
`
const CardText = styled.p`
  max-width: 720px;
  width: 100%;
  margin: 0 auto 10px auto;
`
const CardFigure = styled.figure`
  position: relative;
  margin: 0 0 20px;
  height: 84px;
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .gatsby-image-wrapper {
    display: inline-block;
  }
`
const CardAction = styled.div`
  position: absolute;
  z-index: 1;
  right: auto;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  ${BreakpointUp.lg`
    top:50%;
    left:auto;
    right:-15px;
    transform:translateY(-50%);
  `}
  ${BreakpointUp.xxl`
    right:-25px;
  `}
  button {
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0.5px 0px 0px 0px rgb(3 51 102 / 20%);
    cursor: default;
    width: 40px;
    height: 40px;
    ${BreakpointUp.xxl`
      width:60px;
      height:60px;
    `}
    span {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid red;
      border-bottom: none;
      ${BreakpointUp.lg`
        border-top: 6px solid transparent;
        border-left: 8px solid red;
        border-bottom: 6px solid transparent;
        border-right:none;
      `}
      ${BreakpointUp.xl`
        border-top: 8px solid transparent;
        border-left: 12px solid red;
        border-bottom: 8px solid transparent;
        border-right:none;
      `}
    }
  }
`
const AboutPage = ({ location, data }) => (
  <Layout location={location}>
    <Seo title="Liberty Metal Structures - Values and Vision" description="Liberty Metal Structures' only goal is the absolute satisfaction of the customers, and that is why customers are the top priority for our friendly professionals." />
    <Section
      xpt="114px"
      mpt="94px"
      pt="64px"
      xpb="0"
      mpb="0"
      pb="0"
      bgColor="transparent"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <HeroContainer>
          <Hero bgAfterBefore="none">
            <Background>
              <StaticImage
                src="../images/banner-static-bg.jpg"
                placeholder="blurred"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="banner-static-bg"
              />
            </Background>
            <HeroCaption>
              <BreadCrumb className="white" position="static" translate="0">
                <Link to="/">Home</Link>
                <span>About Us</span>
              </BreadCrumb>
              <HeroTitle maxWidth="812px">
              We Build More Than Buildings - We Build Relationships!
              </HeroTitle>
              <HeroDescription maxWidth="1120px">
                <p>
                Liberty Metal Structures is the industry leader in quality metal buildings. Our expertly designed carports, garages, barns, and custom homes are designed with one aim in mind – the absolute satisfaction of our customers. Our friendly professionals are available from the first website browse to the final rivet and beyond, because our commitment to building a confident connection with our clients is our number one priority. While other dealers chase the bottom line, we focus on creating lasting relationships that are as durable and beautiful as our metal buildings. At Liberty, our customers are family, and our family comes first.
                </p>
              </HeroDescription>
            </HeroCaption>
            <HeroVideo>
              <StaticImage
                src="../images/about.jpg"
                placeholder="blurred"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="about"
              />
            </HeroVideo>
          </Hero>
        </HeroContainer>
      </Container>
    </Section>
    <Section pt="0" xpb="60px" mpb="40px" pb="30px" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionAbout>
          <AboutBackground>
            <StaticImage
              src="../images/about-bg.jpg"
              placeholder="blurred"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="about-bg"
            />
          </AboutBackground>
          <AboutGrid>
            <AboutGridLeft>
              <StaticImage
                src="../images/statue-liberty.png"
                placeholder="blurred"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Statue Liberty"
              />
            </AboutGridLeft>
            <AboutGridRight>
              <MarkdownContent>
                <h2>What Liberty Means to Us </h2>
                <p>
                We value more than quarterly earnings at Liberty Metal Structures. The essentials of Liberty go beyond basic business; we aim to provide a quality, trustworthy relationship to every customer. “We hold these truths to be self-evident”, that all business conducted at Liberty will show our commitment to the following values:
                </p>

                <h3>Honesty</h3>
                <p>
                At Liberty, we believe that our word is our bond. When we name a price, we stand by it. When we set a date, we deliver. And when we guarantee the highest quality steel structure in the business, you can rest assured that our experts will stand by every galvanized panel. 
                </p>

                <h3>Transparency</h3>
                <p>
                We want our customers to have confidence in every step of the process, from the initial custom models to the final installation. Our professional customer service representatives are on hand to discuss the logistics with you every day, regardless of the question. Whether you are concerned or curious, we want you to feel like a member of the family. Our doors are always open to you.
                </p>

                <h3>Trust</h3>
                <p>
                Whether your metal building will house your machinery, your livestock, or your beloved trucks, you rely on the steel frame to hold up under pressure. At Liberty Metal Structures, we want our customers to know that they can always rely on us to put their interests first. We care that you put your trust in our buildings, and we honor our customers by building a culture of trust in the way we do business.
                </p>

                <h3>Customer Oriented</h3>
                <p>
                At Liberty, our customers are our family, and family comes first. While other distributors chase quotas and spreadsheets, we focus on each individual customer, their unique needs, and their personal satisfaction. We’re in the business of creating buildings, and relationships, that last. 
                </p>

                <h3>Accountability</h3>
                <p>
                We believe in accountability; if our customers aren’t satisfied, then neither are we. We won’t sell a single panel that we wouldn’t want to own ourselves. If there’s an issue with an order, you won’t hear excuses from us. At Liberty, we make things right for our customers. 
                </p>
              </MarkdownContent>
            </AboutGridRight>
          </AboutGrid>
        </SectionAbout>
      </Container>
    </Section>
    {/* <Section pt="0" xpb="60px" mpb="50px" pb="45px" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionReview>
          <SectionTitle mb="30px">Customer’s Reviews</SectionTitle>
          <SectionGrid>
            {
              data.allContentfulReview.edges.map((item, i) => {
                if (i < 3) {
                  return (
                    <SectionGridCol key={i}>
                      <ReviewCard data={item.node} />
                    </SectionGridCol>
                  )
                }
              })
            }
          </SectionGrid>
        </SectionReview>
        <ExploreMore>
          <Link to='/reviews'><DefaultButton text="View All Reviews" icon={< LineArrowRight />} size="lg" /></Link>
        </ExploreMore>
      </Container>
    </Section> */}

    <Section pt="0" xpb="60px" mpb="40px" pb="30px" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionInnerWhite
          pl="0px"
          pr="0px"
          mpl="0px"
          mpr="0px"
          xpl="0px"
          xpr="0px"
        >
          <SectionTitle pl="4.166%" pr="4.166%">
            Be Free & Easy: The Liberty Buying Process
          </SectionTitle>
          <SectionDescription
            mb="30px"
            pl="4.166%"
            pr="4.166%"
            maxWidth="1496px"
          >
            <p>
            When you’re ready to buy the metal building of your dreams, nothing should stand in the way! Liberty Metal Structures is committed to providing the smoothest process to our customers. Our step-by-step buying process can be followed here:
            </p>
          </SectionDescription>
          <GridRow>
            <GridCol>
              <Card className="card">
                <CardFigure className="card-img">
                  <StaticImage
                    src="../svg/select-building.svg"
                    placeholder="blurred"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Select Your Building"
                  />
                </CardFigure>
                <CardBody className="card-body">
                  <CardTitle>Select Your Building</CardTitle>
                  <CardText>
                  Survey your site and find the building you love. Try out the 3D visualizer to design with our custom options! Add extra windows or doors and experiment with colors.  
                  </CardText>
                </CardBody>
                <CardAction className="card-action">
                  <button type="button" aria-label="button">
                    <span></span>
                  </button>
                </CardAction>
              </Card>
            </GridCol>
            <GridCol>
              <Card className="card">
                <CardFigure className="card-img">
                  <StaticImage
                    src="../svg/place-order.svg"
                    placeholder="blurred"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Place Your Order"
                  />
                </CardFigure>
                <CardBody className="card-body">
                  <CardTitle>Place Your Order</CardTitle>
                  <CardText>
                  Call one of our friendly experts and get a no-hidden-cost estimate. You can discuss estimates for several metal buildings. If you are still in decision mode, our helpful associates will explain the costs of custom options. 
                  </CardText>
                </CardBody>
                <CardAction className="card-action">
                  <button type="button" aria-label="button">
                    <span></span>
                  </button>
                </CardAction>
              </Card>
            </GridCol>
            <GridCol>
              <Card className="card">
                <CardFigure className="card-img">
                  <StaticImage
                    src="../svg/choose-payment.svg"
                    placeholder="blurred"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Choose Your Payment Plan"
                  />
                </CardFigure>
                <CardBody className="card-body">
                  <CardTitle>Choose Your Payment Plan</CardTitle>
                  <CardText>
                  Liberty Metal provides Rent-to-Own and Financing options for every budget. We require no credit checks, and we’ll make sure that you are approved the same day!
                  </CardText>
                </CardBody>
                <CardAction className="card-action">
                  <button type="button" aria-label="button">
                    <span></span>
                  </button>
                </CardAction>
              </Card>
            </GridCol>
            <GridCol>
              <Card className="card">
                <CardFigure className="card-img">
                  <StaticImage
                    src="../svg/prepare-site.svg"
                    placeholder="blurred"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Prepare the Site"
                  />
                </CardFigure>
                <CardBody className="card-body">
                  <CardTitle>Prepare the Site</CardTitle>
                  <CardText>
                  Choosing a foundation and preparing the site for delivery is easy when you have Liberty experts on your side! We’ll walk you through the permit process.
                  </CardText>
                </CardBody>
                <CardAction className="card-action">
                  <button type="button" aria-label="button">
                    <span></span>
                  </button>
                </CardAction>
              </Card>
            </GridCol>
            <GridCol>
              <Card className="card">
                <CardFigure className="card-img">
                  <StaticImage
                    src="../svg/delivery-installation.svg"
                    placeholder="blurred"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Delivery and Installation"
                  />
                </CardFigure>
                <CardBody className="card-body">
                  <CardTitle>Delivery and Installation</CardTitle>
                  <CardText>
                  Every building from Liberty Metal comes with free delivery. When we set a date, you can count on us. Our professional crew will seal each structure for energy efficiency.
                  </CardText>
                </CardBody>
                <CardAction className="card-action">
                  <button type="button" aria-label="button">
                    <span></span>
                  </button>
                </CardAction>
              </Card>
            </GridCol>
            <GridCol>
              <Card className="card">
                <CardFigure className="card-img">
                  <StaticImage
                    src="../svg/satisfied-customer.svg"
                    placeholder="blurred"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Satisfied Customer"
                  />
                </CardFigure>
                <CardBody className="card-body">
                  <CardTitle>Satisfied Customer</CardTitle>
                  <CardText>
                  Our professionals finish every sale with attention to detail; we want every customer to be very satisfied!
                  </CardText>
                </CardBody>
                <CardAction className="card-action">
                  <button type="button" aria-label="button">
                    <span></span>
                  </button>
                </CardAction>
              </Card>
            </GridCol>
          </GridRow>
        </SectionInnerWhite>
      </Container>
    </Section>

    <Section pt="0" xpb="60px" mpb="40px" pb="30px" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <MarkdownBody>
          <MarkdownContent maxWidth="1496px">
            <h2>Give Me Liberty!</h2>
            <p>
            You deserve the very best in quality. Quality buildings and quality service are the reason Liberty Metal Structures is the industry leader in customer satisfaction. You won’t find more friendly professionals than the team at Liberty. Our company story is providing the very best in all the following areas:
            </p>
            <ul>
              <li>An incredible variety of metal buildings for every purpose</li>
              <li>Modern, custom options to suit every style</li>
              <li>The lowest prices anywhere – guaranteed! </li>
              <li>Standard warranties on every frame and panel </li>
              <li>Best lead time in the industry </li>
              <li>Expert-backed, certified quality </li>
              <li>Best customer satisfaction in the business </li>
              <li>A team of professionals focused on you!</li>
            </ul>
          </MarkdownContent>
        </MarkdownBody>
      </Container>
    </Section>

    <Section pt="0" xpb="60px" mpb="40px" pb="30px" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionExpert>
          <ExpertBackground>
            <StaticImage
              src="../images/expert-bg.jpg"
              placeholder="blurred"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Expert"
            />
          </ExpertBackground>
          <SectionExpertContent>
            <SectionTitle color="#fff" maxWidth="1190px">
            Choose Liberty Today!
            </SectionTitle>
            <SectionDescription color="#fff" maxWidth="1190px" mb="30px">
              <p>Liberty Metal Structures provides the highest quality in metal buildings and customer service. When you buy a custom metal building, you deserve the very best. Choose a company that values honesty, accountability, and trust over the profit margin. Choose Liberty Metal Structures, where every customer is family, and family comes first!</p>
              <p>Get the best metal buildings at the best prices! Call us today at</p>
            </SectionDescription>
            <SectionToolbar>
              <a href="tel:8004407309" aria-label="Phone">
                <PrimaryButton
                  textAfter="(800) 440-7309"
                  icon={<PhoneIcon />}
                  size="lg"
                />
              </a>
            </SectionToolbar>
          </SectionExpertContent>
        </SectionExpert>
      </Container>
    </Section>
  </Layout>
)

export default AboutPage

export const query = graphql`
  query AboutPageQuery {
    allContentfulReview {
      edges {
        node {
          title
          author
          authorRole
          authorImage {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          date(fromNow: true)
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
